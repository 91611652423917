<!-- Copyright 2022, Common Good Learning Tools LLC -->
<template><div>
	<h3>Create Associations</h3>

	<p>To create an association for an item (domain, strand, cluster, or standard) in the framework/document:</p>
	<ul>
		<li><span v-html="link('signing_in', 'Sign in')"></span> to <span v-html="app_name"/> with Framework / System Admin or Editor permissions.</li>
		<li>Select a framework you want to edit to create an association.</li>
		<li><nobr><v-icon small>fas fa-edit</v-icon></nobr> Enter <strong>edit mode</strong> by clicking the <strong>Edit</strong> icon from the toolbar</li>
		<li>Select the item you want to edit in the framework tree (domain, strand, cluster, or standard)</li>
		<li>Once you’ve selected an <strong>item card</strong> will appear. <nobr><v-icon small>fas fa-edit</v-icon></nobr>Select the <strong>EDIT BUTTON.</strong></li>
		<li>From the <strong>edit menu</strong>, select <strong>MAKE ASSOCIATIONS.</strong></li> 
		<img srcset="/docimages/edit_menu.png 3x" class="k-help-img block">
		<li>A <strong>Make Associations</strong> pop up window will appear:</li>
		<img srcset="/docimages/make_associations_pop_up.png 2x" class="k-help-img float-right">
			<ul class="k-no-clear">
				<li>Use the drop down menu to choose a framework you want to <strong>Associate with Items from.</strong></li>
				<li>Select the <strong>Association Type</strong> (typically <strong>Related To</strong>).</li>
				<li>Use the <strong>Suggestion Criteria</strong> search to find keywords from the framework to make item associations. </li>
					<ul>
						<li>Type your search words and choose <strong>SUGGEST</strong> if needed. Selecting an item from the suggestion criteria list will jump you to that area of the framework shown below.</li>
					</ul>
				<li>Navigate the framework tree at the bottom of the pop up window to <strong>CHECK</strong> the item(s) to associate.</li>
					<ul>
						<li>A <strong>checkmark</strong> will appear for associated items as well as a label on the original item.</li>
						<li>Hover over the check to reveal an <strong>X to remove an associated item.</strong> </li>
					</ul>
			</ul>
	</ul>
	<p>See <span v-html="link('editing', 'Authoring/editing standards')"> </span>for details on other operations available from the editing menu.</p>
</div></template>

<script>
	import HelpMixin from './HelpMixin'
	export default {
		mixins: [HelpMixin],
	}
</script>

<style lang="scss">
</style>
	